var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "outer" }, [
    _c(
      "header",
      [
        _c(
          "el-radio-group",
          {
            attrs: { size: "mini" },
            model: {
              value: _vm.documentType,
              callback: function ($$v) {
                _vm.documentType = $$v
              },
              expression: "documentType",
            },
          },
          _vm._l(_vm.DOCUMENT_TYPES, function (type) {
            return _c(
              "el-radio-button",
              { key: type, attrs: { label: type } },
              [_vm._v(" " + _vm._s(_vm._f("capitalize")(type)) + " ")]
            )
          }),
          1
        ),
        _c(
          "el-button",
          { attrs: { size: "mini" }, on: { click: _vm.createTemplate } },
          [_vm._v(" New ")]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "inner" },
      [
        _vm.loading
          ? _c("loader")
          : _c(
              "ul",
              _vm._l(_vm.sorted_templates, function (template) {
                return _c(
                  "li",
                  {
                    key: template.id,
                    on: { click: () => _vm.editTemplate(template.id) },
                  },
                  [
                    _c("span", [_vm._v(_vm._s(template.name))]),
                    _c("svgicon", {
                      staticClass: "triangle",
                      attrs: { name: "triangle" },
                    }),
                  ],
                  1
                )
              }),
              0
            ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }